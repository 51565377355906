<template>
  <div class="transactions">
    <Module-Header title="G$ Transactions">
      <el-button class="export" @click="exportTransactions"><img src="@/assets/images/link.png" />Export</el-button>
    </Module-Header>

    <div class="search-box">
      <el-input placeholder="Keywords(e.g. Member ID or eatery name)" 
        prefix-icon="el-icon-search" v-model="listQ.like_member_id_or_eatery_name" style="flex: 1"></el-input>

      <el-select v-model="listQ.coupon_type" clearable placeholder="All coupon types" style="margin-left:20px">
        <el-option
          v-for="(item,index) in types" :key="index" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <el-select v-model="listQ.status" clearable placeholder="All status" style="margin-left:20px">
        <el-option
          v-for="(item,index) in status" :key="index" :label="item.label" :value="item.value">
        </el-option>
      </el-select> 
      
      <Date-picker label="Create Time" :joiningDate="listQ.start_date" @updateDate="changeDate"></Date-picker>

      <el-button class="search" icon="el-icon-search" @click="getData"> Search </el-button>
    </div>

    <div class="table-box">
      <el-table :data="tableData" ref="table" style="width:100%;">
        <el-table-column v-for="(its, idx) in tableCate" :key="idx"
          :prop="its.prop" :label="its.label" :min-width="its.width"> </el-table-column>
      </el-table>
      <Pagination
        :total="total"
        :autoScroll='false'
        :page.sync="listQ._start"
        :limit.sync="listQ._limit"
        layout="prev, pager, next"
        :pager-count="count"
        @pagination="getData"
      />
    </div>

  </div>
</template>

<script>
import { getGreenDollarPointTransactions } from '../../api/GTransactions';
import exportToExcel from "@/utils/exportExcel";

export default {
  data() {
    return {
      tableCate: [
        {label: 'Transaction ID', prop: 'transactionNo', width: '170'},
        {label: 'Member ID', prop: 'memberId', width: '170'},
        {label: 'Stamps', prop: 'stamps', width: '170'},
        {label: 'Coupon Type', prop: 'couponType', width: '200'},
        {label: 'Status', prop: 'status', width: '180'},
        {label: 'Create Time', prop: 'createAt', width: '160'},
        {label: 'Completion Time', prop: 'completedAt', width: '170'},
      ],
      tableData: [],
      total: 0,
      count: 5,
      listQ: {
        like_member_id_or_eatery_name: '',
        coupon_type: '',
        status: '',
        start_date: '',
        end_date: '',
        _start: 1,
        _limit: 20,
      },
      status:[
        {label: 'Pending', value: 0},
        {label: 'Completed', value: 1},
        {label: 'Failed', value: 2},
      ],
      types:[
        {label: 'G$ 250', value: 0},
        {label: 'Cutlery', value: 1},
      ],
    }
  },
  methods: {
    exportTransactions() {
      let data = this.tableData,
      tHeader = [],
      filterVal = [],
      title = 'G$ Transactions'

      this.tableCate.filter(its => {
        tHeader.push(its.label)
        filterVal.push(its.prop)
      })
      exportToExcel(data, title, tHeader, filterVal, () => {})
    },
    changeDate(s) {
      this.listQ.start_date = s ? new Date(s).format('yyyy-MM-dd') : ''
      this.listQ.end_date = s ? new Date(s).format('yyyy-MM-dd') : ''
    },
    getData() {
      let {listQ} = this, form = {
        like_member_id_or_eatery_name: listQ.like_member_id_or_eatery_name,
        coupon_type: listQ.coupon_type,
        status: listQ.status,
        start_date: listQ.start_date,
        end_date: listQ.end_date,
        _start: listQ._start - 1,
        _limit: listQ._limit,
      }
      
      getGreenDollarPointTransactions(form).then(v => {
        console.log(v)
        v.data.map(its => {
          its.stamps = 0
          its.items.filter(item => {
            if(item.stampOperationType == 0) its.stamps += item.stamps
            else its.stamps -= item.stamps
          })
            // console.log(this.types, its.couponType)
          its.couponType = this.types[its.type].label
          its.status = this.status[its.status].label
          its.createAt = its.createdAt ? new Date(its.createdAt).format('yyyy/MM/dd hh:mm:ss') : ''
          its.completedAt = its.completedAt ? new Date(its.completedAt).format('yyyy/MM/dd hh:mm:ss') : ''
          return its
        })
        this.tableData = v.data
        this.total = Number(v.headers['x-total-count']) 
      })
    }
  },
  mounted() {

    this.getData()
  }
}
</script>

<style scoped lang="scss">
.transactions {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  .search-box{
    display: flex;
    font-size: 0.875rem;
    width: 100%;
    margin-top: 3.125rem;

    .search {
      margin-left: 1.25rem;
    }
  }

}
</style>